:root {
  font-family: sans-serif;
  color-scheme: light;
}

body {
  margin: 0;
  /* background-color: #08040F; */
}

.container {
  display: grid;
  grid-template-columns: [back-button] min-content [sketch-menu] auto;
  grid-template-rows: [navigation] 3.6em [canvas] auto;
  height: 100vh;
  max-width: 100vw;
}

.p5Canvas {
  grid-row: canvas;
  grid-column: 1 / 3;
  overflow: visible;
  justify-self: center;
  align-self: center;
  max-width: 100%;
  max-height: calc(100vh - 3.6em);
  outline: 1px solid #f0f0f0;
}

.container.container--gui-open {
  width: calc(100vw - 500px);
}

.sketch-menu {
  grid-column: sketch-menu;
  justify-self: center;
  --primary: 250deg, 25%, 50%;
  --surface: 240deg, 50%, 10%;
  --on-surface: 0deg, 0%, 100%;
  color: white;
}

.gui {
  background: hsl(240deg, 10%, 10%);
}

.back-button {
  text-align: center;
  padding: 0 0.375em;
  line-height: 1.5em;
  color: white;
  font-size: 2.5rem;
  font-weight: lighter;
  text-shadow: 0 0 3px #00000011;
  text-decoration: none;
  cursor: pointer;
}
